<template>
  <div>
    <div class="vx-row">
      <vs-input
        v-validate="'required|email'"
        data-vv-validate-on="blur"
        name="email"
        icon-no-border
        icon="icon icon-user"
        icon-pack="feather"
        label-placeholder="Email"
        :placeholder="$t('Email')"
        v-model="email"
        class="w-full"
      />
      <span class="text-danger text-sm">{{ errors.first("email") }}</span>
    </div>

    <div class="vx-row mt-2" style="position: relative;">
      <vs-input
        data-vv-validate-on="blur"
        v-validate="'required|min:6'"
        :type="showPassword ? 'text' : 'password'"
        name="password"
        icon-no-border
        icon="icon icon-lock"
        icon-pack="feather"
        label-placeholder="Password"
        :placeholder="$t('Password')"
        v-model="password"
        class="w-full mt-6"
      />
      <span class="text-danger text-sm">{{ errors.first("password") }}</span>

      <!-- Eye icon for toggling password visibility -->
      <i
        class="feather"
        :class="showPassword ? 'icon-eye-off' : 'icon-eye'"
        @click="togglePasswordVisibility"
        style="cursor: pointer; position: absolute; right: 15px; top: 35px;"
      ></i>
    </div>

    <div class="flex flex-wrap justify-between my-5">
      <vs-checkbox v-model="checkbox_remember_me" class="text-sm mb-3">
        {{ $t("RememberMe") }}
      </vs-checkbox>

      <router-link to="/pages/ForgotPassword">{{ $t("ForgotPassword") }}</router-link>
    </div>

    <div class="flex flex-wrap justify-between mb-3">
      
      <vs-button color="primary" :disabled="!validateForm" @click="loginJWT">{{ $t("Login") }}</vs-button>
      <!-- <vs-button type="border" @click="GoRegister" class="mt-6">{{ $t("Register") }}</vs-button> -->
    </div>
  </div>
</template>

<script>
import moduleAuth from "@/store/auth/moduleAuth.js";
export default {
  data() {
    return {
      email: "",
      showPassword: false, // Toggle for password visibility
      password: "",
      checkbox_remember_me: false
    };
  },
  created() {
    if (!moduleAuth.isRegistered) {
      this.$store.registerModule("_auth1", moduleAuth);
      moduleAuth.isRegistered = true;
    }
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.email != "" && this.password != "";
    }
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    GoRegister() {
      this.$router.push("/register");
    },
    ForgotPassword() {
      this.$router.push("/pages/ForgotPassword");
    },
    checkLogin() {
      if (this.$store.state._auth1.isUserLoggedIn()) {
        this.$vs.notify({
          title: this.$t("LoginAttempt"),
          text: this.$t("Youarealreadyloggedin"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning"
        });
        return false;
      }
      return true;
    },
    loginJWT() {
      if (!this.checkLogin()) return this.$router.push("/");

      this.$vs.loading();

      const payload = {
        checkbox_remember_me: this.checkbox_remember_me,
        userDetails: {
          UserName: this.email,
          password: this.password,
          Language: 0,
          userType: 'SuperAdmin',
          fireBaseToken: '',
          phoneNumber: ''
        }
      };

      this.$store
        .dispatch("_auth1/loginJWT", payload)
        .then(res => {
          if (res.Status == 200) {
            this.$vs.loading.close();
            this.$vs.notify({
              text: "Login Successfuly",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "warning"
            });
          } else {
            this.$vs.notify({
              title: this.$t("Error"),
              text: "ايميل او كلمه المرور خاطئه",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger"
            });
            if (res.data.userData.UserType == "Admin") {
              this.$router.push("/").then(() => {
                location.reload("/");
              });
            } else {
              this.$router
                .push(this.$router.currentRoute.query.to || "/")
                .then(() => {
                  location.reload("/");
                });
            }
          }
        })
        .catch(() => {
          this.$vs.notify({
            title: this.$t("Error"),
            text: "ايميل او كلمه المرور خاطئه",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger"
          });
          this.$vs.loading.close();
        });
    }
  }
};
</script>
