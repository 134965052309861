<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center" id="page-login">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">

          <div class="vx-row no-gutter justify-center items-center">

            <div class="vx-col hidden lg:block lg:w-1/2">
              <!-- <h2 class="m-2" style="color:#6A1C56" v-if="IncomeCode!=null&&IncomeCode!=undefined&&IncomeCode!='undefined'"> -->
                <img  :src="defaultProfilePic1" style="width:90%; height: 600px;padding-top: 50px;" alt="login" class="mx-auto">

              <div class="vx-card__title mb-16 mt-10"></div>

            </div>

            <div class="vx-col  d-theme-dark-bg" style="margin-left: auto !important; margin-right: auto !important;width: 450px; border-radius: 20px; height: 522px;">
              <div class="px-8 pt-8 login-tabs-container" >

                <img :src="defaultProfilePic2" style="width:150px;height: 85px;" alt="login" class="mx-auto">
                <!-- <img src="@/assets/images/logo/ebdaaAcademy.png" style="width:180px" alt="login" class="mx-auto"> -->

                <div class="vx-card__title mb-12 mt-5"></div>

                <div class="vx-card__title mb-4 mt-5">
                  <h4 class="mb-4">{{$t('Login')}}</h4>
                  <p>{{ $t("Welcome") }} {{welcomelog}}.</p>
                </div>

                <login-jwt></login-jwt>


                <!-- <vs-tabs>
                  <vs-tab label="JWT">

                  </vs-tab>

                   <vs-tab label="Firebase">
                    <login-firebase></login-firebase>
                  </vs-tab>

                   <vs-tab label="Auth0">
                    <login-auth0></login-auth0>
                  </vs-tab>
                </vs-tabs> -->

              </div>
            </div>

          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>


<script>
import LoginJwt from "./LoginJWT.vue";
// import Logo from "@/layouts/components/Logo.vue";

export default {
  computed: {
      defaultProfilePic1() {
      if (window.location.hostname.includes("clixy.net")) {
        return require("@/assets/images/clixy.png");
      } else if (window.location.hostname.includes("ezzeyfind.com")) {
        return require("@/assets/images/ezzyFindLogo.jpg");
      } else {
        return require("@/assets/images/clixy.png");
      }
    },
    defaultProfilePic2() {
      if (window.location.hostname.includes("clixy.net")) {
        return require("@/assets/images/clicxy.png");
      } else if (window.location.hostname.includes("ezzeyfind.com")) {
        return require("@/assets/images/ezzyf.png");
      } else {
        return require("@/assets/images/clicxy.png");
      }
    }
    ,welcomelog(){
      if (window.location.hostname.includes("clixy.net")) {
        return "Clixy";
      } else if (window.location.hostname.includes("ezzeyfind.com")) {
        return "EzzeyFind";
      } else {
        return "Clixy";
      }
    }
    },
  components: {
    LoginJwt,
    // Logo
   
  }
};
</script>
<style lang="scss">
.login-tabs-container {
  min-height: 505px;

  .con-tab {
    padding-bottom: 14px;
  }

  .con-slot-tabs {
    margin-top: 1rem;
  }
}
</style>
